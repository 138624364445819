import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
import CardImg from "../../assests/CardImg.png";
import { Spin } from "antd";
import axios from "axios";
import { checkIfProfileIsComplete } from "../../helpers/helpers";

const UserAccount = () => {
  let id = localStorage.getItem("userId");
  const initialstate = {
    first_name: "",
    last_name: "",
    email: "",
    update: false,
    isProfileComplete: true,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { first_name, last_name, email, update, isProfileComplete } = FormData;
  useEffect(() => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/user/get_user/${id}`;
    axios.get(link).then((res) => {
      if (res.data.success) {
        const isProfileComplete = checkIfProfileIsComplete(res.data.data, [
          "first_name",
          "last_name",
          "email",
          "contact",
          "gender",
          "personal_address.country",
          "personal_address.street_address",
          "personal_address.apt",
          "personal_address.city",
          "personal_address.State",
          "personal_address.zip_code",
        ]);
        setFormData({
          ...FormData,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          email: res.data.data.email,
          update: true,
          isProfileComplete,
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="container col-11 mxAuto maxWidth">
          <h1 className="pt-4">Account</h1>
          <p>
            <strong>{first_name + " " + last_name}</strong> <span>{email}</span>
          </p>
          {isProfileComplete ? null : (
              <p
                style={{
                  backgroundColor: "#FFD700",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "100%",
                  maxWidth: "300px",
                }}
              >
                Personal Info not completed
              </p>
            )}
          <div className="pt-5">
            <div className="row">
              <div className="col-md-4 col-11 mxAuto my-3">
                <NavLink to={`/personalinfo/${id}`} className="Black">
                  <div className="card Radius_10  account shadow ">
                    <div className="card-body p-4 ">
                      <img src={CardImg} alt=""></img>
                      <h3 className="pt-3  uAccount">Personal Info &#62; </h3>
                      <p className="card-text font16">
                        Personal info details can be viewed and edited.
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4 col-11 mxAuto my-3">
                <NavLink className="Black" to={"/security/" + id}>
                  <div className="card Radius_10 account shadow">
                    <div className="card-body p-4 ">
                      <img src={CardImg} alt=""></img>
                      <h3 className="pt-3 uAccount">
                        Login &#38; Security &#62;
                      </h3>
                      <p className="card-text font16">
                        Visit the security page to review the login information.
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-4 col-11 mxAuto my-3">
                <NavLink to="/notifications" className="Black">
                  <div className="card Radius_10  account shadow">
                    <div className="card-body p-4 ">
                      <img src={CardImg} alt=""></img>
                      <h3 className="pt-3 uAccount">Notifications &#62;</h3>
                      <p className="card-text font16">
                        Explore your account notifications.
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-4 col-11 mxAuto my-3">
                <NavLink className="Black" to="/privacyandsharing">
                  <div className="card Radius_10 account shadow">
                    <div className="card-body p-4">
                      <img src={CardImg} alt=""></img>
                      <h3 className="pt-3 uAccount">
                        Privacy &#38; Sharing &#62;
                      </h3>
                      <p className="card-text font16">
                        See your privacy and sharing.
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-4 col-11 mxAuto my-3">
                <NavLink className="Black" to={"/invite/" + id}>
                  <div className="card Radius_10 account shadow ">
                    <div className="card-body p-4">
                      <img src={CardImg} alt=""></img>
                      <h3 className="pt-3 uAccount">Invite Friends &#62;</h3>
                      <p className="card-text font16">
                        Send your friends a personal invitation to Black
                        Booking.
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>

              <div className="col-md-4 col-11 accounts my-3">
                <NavLink to={"/userbookings/" + id} className="Black">
                  <div className="card Radius_10 account shadow">
                    <div className="card-body p-4">
                      <img src={CardImg} alt=""></img>
                      <h3 className="pt-3 uAccount">Bookings &#62; </h3>
                      <p className="card-text font16">
                        Your account's Bookings record can be found here.
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <p className="text-center">Need to deactivate your account?</p>
            <u>
              <NavLink to={"/selectreason/" + id} className="orangecolor">
                <h6 className="text-center orangecolor">
                  Take care of that now
                </h6>
              </NavLink>
            </u>
          </div>
        </div>
      </>
    );
  }
};

export default UserAccount;
