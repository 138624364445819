import moment from "moment";

/**
 *
 * @param {string} pwd
 * @description Returns the current strength of the password
 * @returns {number} 0: weird, 1: weak, 2: ok, 3: strong, 4: awesome
 */
export const testPassword = (pwd, min = 6) => {
  const simplePasswords = [
    "123456",
    "123123",
    "qwerty",
    "asdfgh",
    "zxcvbn",
    "qweasdzxc",
    "qwerty123",
    "asdfghjkl",
    "zxcvbnm",
    "qwertyuiop",
    "asdfghjklzxcvbnm",
    "321321",
    "1234567890",
    "qwertyuiopasdfghjklzxcvbnm",
    "1234567890qwertyuiop",
    "qwertyuiopasdfghjklzxcvbnm1234567890",
    "admin",
    "admin123",
    "admin321",
    "asdf123",
    "123abc",
    "abc123",
    "654321",
    "012345",
    "password",
    "123pass",
    "pass123",
    "123456abc",
  ];

  let level = 0;

  if (simplePasswords.includes(pwd) || pwd.length < min) {
    return level;
  }
  const array = [];
  array[0] = pwd.match(/[A-Z]/);
  array[1] = pwd.match(/[a-z]/);
  array[2] = pwd.match(/\d/);
  array[3] = pwd.match(/[!_.-@,|"']/);

  let sum = 0;
  for (const element of array) {
    sum += element ? 1 : 0;
  }

  /**
      sum = 0: weird...
      sum = 1: weak
      sum = 2: ok
      sum = 3: strong
      sum = 4: awesome
  */

  switch (sum) {
    case 0:
      level = 0;
      break;
    case 1:
      level = 1;
      break;
    case 2:
      level = 2;
      break;
    case 3:
      level = 3;
      break;
    case 4:
      level = 4;
      break;
    default:
      level = 0;
      break;
  }

  return level;
};

/**
 *
 * @param {string} time
 */
export const convert12HourTo24Hour = (time) => {
  let [hours, minutes] = time.replace(/am|pm|AM|PM/g, "").split(":");
  if (!time || typeof time !== "string" || !minutes) {
    return time;
  }
  const unit = time.toLowerCase().endsWith("am") ? "am" : "pm";

  if (hours === "12") {
    hours = "00";
  }

  if (unit === "pm") {
    hours = parseInt(hours, 10) + 12;
  }

  if (parseInt(hours, 10) < 12 && hours.length === 1) {
    hours = `0${hours}`;
  }

  return `${hours}:${minutes}`;
};

export const mergeTimeInDate = (date, time, isUTC = false) => {
  const dateToMoment = moment(date).format("YYYY-MM-DDT");
  return `${dateToMoment}${time}:00${isUTC ? ".000Z" : ""}`;
};

export const checkIfProfileIsComplete = (obj, keys) => {
  const checkNestedKeys = (obj, keys) => {
    for (const key of keys) {
      const keyParts = key.split(".");
      let current = obj;
      for (const part of keyParts) {
        if (current[part] === undefined || current[part] === "") {
          return false;
        }
        current = current[part];
      }
    }
    return true;
  };

  return checkNestedKeys(obj, keys);
};

/**
 *
 * @param {string} time
 * @description Checks if the passed time in format hh:mma is in the past
 * @returns {boolean} true if the time is in the past, false otherwise
 */
export const isTimeInPast = (time) => {
  const now = moment();
  const inputTime = moment(time, "hh:mma");
  return inputTime.isBefore(now);
};

/**
 *
 * @param {string[]} times - Array of times in format hh:mma
 * @param {number} duration - Duration in minutes
 * @description Filters the times that fall within the given duration from the current time
 * @returns {string[]} Filtered array of times
 */
export const filterTimesWithinDuration = (times, duration, selectedTime) => {
  const now = moment(selectedTime, "hh:mma");
  const end = moment().add(duration, "minutes");

  if (!duration || times.length === 0 || !selectedTime) {
    return times;
  }

  return times.filter((time) => {
    const inputTime = moment(time, "hh:mma");
    return !inputTime.isBetween(now, end);
  });
};
