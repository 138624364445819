import React, { useCallback, useEffect, useMemo, useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import { useParams } from "react-router-dom";
import Payout from "./Vendor/Payout";
import paymentImg from "../assests/paymentImg.png";
import axios from "axios";
import UserBooking from "../API/UserBooking/UserBooking";
import moment from "moment";
import { message, DatePicker, Switch, Spin } from "antd";
import dateFormat from "dateformat";
import { isTimeInPast } from "../helpers/helpers";

const PaymentBooking = () => {
  const user_id = localStorage.getItem("userId");
  const initialstate = {
    Instant_Offered: false,
    instantday: "",
    Booking_type: "",
    time: [],
    first_name: "",
    last_name: "",
    booking_date: "",
    booking_time: "",
    business_name: "",
    payment_type: "",
    service_deposit: 0,
    city: "",
    State: "",
    services: [],
    paymentType: "HalfPay",
    updated: false,
    travelToMeOffered: false,
    trevel_to_me: false,
    selectedServiceTime: 15,
    getTime: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const [total_bill, setTotalBill] = useState(0);
  const [totalBookingAmount, setTotalBookingAmount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const {
    Instant_Offered,
    travelToMeOffered,
    Booking_type,
    payment_type,
    paymentType,
    time,
    first_name,
    last_name,
    booking_date,
    trevel_to_me,
    booking_time,
    business_name,
    service_deposit,
    services,
    updated,
    selectedServiceTime,
    getTime,
  } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  const validation = (email, trnsID, paypalResponseData = {}) => {
    // Validate all required fields
    const validationErrors = {
      Booking_type: !Booking_type && "Select the type of Booking",
      first_name: !first_name && "First Name is Required",
      last_name: !last_name && "Last Name is Required",
      last_name_length:
        last_name &&
        last_name.length < 3 &&
        "Last Name must be 3 characters long",
      booking_date: !booking_date && "Booking Date is Required",
      booking_time: !booking_time && "Booking time must be selected",
      cart_items: cartItems.length <= 0 && "Add Services from List",
    };

    // Find first validation error
    const error = Object.entries(validationErrors).find(
      ([key, value]) => value
    );

    if (error) {
      // Show error message and update form state
      const [field, errorMessage] = error;
      setFormData({
        ...FormData,
        error: errorMessage,
      });
      message.error(errorMessage);
      return;
    }

    // All validation passed, proceed with booking
    UserBooking({
      vendor_id: _id,
      user_id,
      Booking_type,
      first_name,
      last_name,
      cartItems,
      booking_date,
      booking_time,
      service_durantion: selectedServiceTime,
      trevel_to_me,
      total_bill: totalBookingAmount,
      paymentType,
      service_deposit,
      total_deposit: total_bill,
      payerEmail: email,
      trnsID,
      remaining_balance: remaining,
      paypalResponseData: JSON.stringify(paypalResponseData),
    });
  };

  const { _id } = useParams();

  useEffect(() => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/vendor/get_vendor/${_id}`;
    axios.get(link).then((res) => {
      if (res.data.success) {
        if (res.data.data.payment_type === "full") {
          setFormData({
            ...FormData,
            paymentType: "FullPay",
            business_name: res.data.data.business_information.business_name,
            services: res.data.data.services,
            Instant_Offered: res.data.data.instant_booking,
            travelToMeOffered: res.data.data.trevel_to_me,
            payment_type: res.data.data.payment_type,
            working_hours: res.data.data.working_hours,
            updated: true,
          });
        } else {
          setFormData({
            ...FormData,
            paymentType: "HalfPay",
            business_name: res.data.data.business_information.business_name,
            services: res.data.data.services,
            Instant_Offered: res.data.data.instant_booking,
            travelToMeOffered: res.data.data.trevel_to_me,
            payment_type: res.data.data.payment_type,
            working_hours: res.data.data.working_hours,
            updated: true,
          });
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const [cartItems, setCartItems] = useState([]);

  const onAdd = (item) => {
    let find = false;
    // eslint-disable-next-line
    cartItems.map((name) => {
      // eslint-disable-next-line
      if (name.service_name == item.service_name) {
        find = true;
      }
    });
    if (find === false) {
      let list = {
        service_price: item.service_price,
        service_name: item.service_name,
        serviceid: item.serviceid,
        service_time: item.service_time,
        service_deposit: item.service_deposit,
      };
      // eslint-disable-next-line
      const exist = cartItems.find((x) => x._service_name == item.service_name);
      if (exist) {
        setCartItems(
          cartItems.map((x) =>
            // eslint-disable-next-line
            x == item ? { ...exist, qty: exist.qty + 1 } : x
          )
        );
      } else {
        setCartItems([...cartItems, { ...list, qty: 1 }]);
      }
      setFormData({
        ...FormData,
        booking_time: "",
      });
    } else if (find === true) {
      message.success({
        content: item.service_name + " is already added",
        duration: "7",
      });
    }
    if (find === false) {
      cartItems.length < 1
        ? setFormData({
            ...FormData,
            selectedServiceTime: item.service_time,
          })
        : setFormData({
            ...FormData,
            selectedServiceTime: selectedServiceTime + item.service_time,
          });
    }
  };

  useEffect(() => {
    if (!cartItems.length) {
      return;
    }
    const total = cartItems.reduce(
      (a, c) =>
        a +
        c.qty *
          (paymentType === "HalfPay" && Booking_type !== "InstantBooking"
            ? c.service_deposit ?? c.service_price
            : c.service_price),
      0
    );
    const totalPrice = cartItems.reduce(
      (a, c) => a + c.qty * c.service_price,
      0
    );
    setTotalBookingAmount(totalPrice);
    setTotalBill(total);
    setFormData((prev) => {
      const serviceCharges = cartItems.reduce(
        (a, c) => a + c.qty * (c.service_deposit ?? 0),
        0
      );
      if (paymentType === "HalfPay" && Booking_type !== "InstantBooking") {
        const cartTotal = cartItems.reduce(
          (a, c) => a + c.qty * c.service_price,
          0
        );
        setRemaining(cartTotal - total);
      } else {
        setRemaining(0);
      }
      return {
        ...prev,
        service_deposit: serviceCharges,
        total_deposit: total,
      };
    });
  }, [cartItems, paymentType, Booking_type]);

  const onRemove = (service) => {
    // eslint-disable-next-line
    const exist = cartItems.find((x) => x.service_name == service.service_name);
    if (exist.qty === 1) {
      setCartItems(
        cartItems.filter((x) => x.service_name !== service.service_name)
      );
    } else {
      setCartItems(
        cartItems.map((x) =>
          // eslint-disable-next-line
          x.service_name == service.service_name
            ? { ...exist, qty: exist.qty - 1 }
            : x
        )
      );
    }
    if (cartItems.length > 1) {
      setFormData({
        ...FormData,
        selectedServiceTime: selectedServiceTime - service.service_time,
      });
    } else {
      setFormData({
        ...FormData,
        selectedServiceTime: 15,
      });
    }
  };

  useEffect(() => {
    if (FormData.Booking_type === "InstantBooking") {
      setFormData((prev) => ({
        ...prev,
        paymentType: "FullPay",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        paymentType: "HalfPay",
      }));
    }
  }, [FormData.Booking_type]);

  const RenderItems = services.map((item) => {
    return (
      <>
        <tr>
          <td className="text-center" style={{ verticalAlign: "middle" }}>
            <span>{item.service_name}</span>{" "}
          </td>
          <td className="text-center" style={{ verticalAlign: "middle" }}>
            ${item.service_price}
          </td>
          <td className="text-center" style={{ verticalAlign: "middle" }}>
            {item.service_time} min
          </td>
          <td className="text-center" style={{ verticalAlign: "middle" }}>
            <button
              type="button"
              id={item.service_name}
              onClick={() => onAdd(item)}
              className="btn Orange White"
            >
              Add
            </button>
          </td>
        </tr>
        <tr>
          <td className="text-center" style={{ verticalAlign: "middle" }}>
            <span>{item?.service_description}</span>
          </td>
        </tr>
      </>
    );
  });

  const onChange = (e) => {
    if (e !== null) {
      const date = e._d;

      setFormData({
        ...FormData,
        getTime: true,
      });
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/vendor/time_slots/${_id}`,
          {
            date: dateFormat(date, "isoDate"),
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            setFormData({
              ...FormData,
              time: [
                ...new Set(
                  res.data.time.filter((item) => {
                    if (moment(date).isAfter(moment())) return true;
                    return !isTimeInPast(item);
                  })
                ),
              ],
              booking_date: moment(res.data.date).format("YYYY/MM/DD"),
              booking_time: "",
              getTime: false,
            });
          }
        });
      return;
    }
    setFormData({
      ...FormData,
      booking_date: "",
    });
  };

  const TimeSelect = (e) => {
    if (!e.target.id || !e.target.value) {
      return;
    }
    setFormData({
      ...FormData,
      booking_time: e.target.value,
    });
  };

  const travelChange = (e) => {
    setFormData({
      ...FormData,
      trevel_to_me: e,
    });
  };
  const paymentChange = (e) => {
    if (e === true) {
      setFormData({
        ...FormData,
        paymentType: "FullPay",
      });
    } else if (e === false) {
      setFormData({
        ...FormData,
        paymentType: "HalfPay",
      });
    }
  };

  const Error = () => {
    if (first_name && first_name.length < 3) {
      return (
        <p className="text-danger">First Name must be 3 characters long</p>
      );
    }
    if (last_name && last_name.length < 3) {
      return <p className="text-danger">Last Name must be 3 characters long</p>;
    }
  };

  // const timeSlots = useMemo(() => {
  //   const duration = cartItems.reduce((a, b) => {
  //     return a + b.service_time;
  //   }, 0);

  //   return filterTimesWithinDuration(time, duration, FormData.booking_time);
  // }, [time, cartItems, FormData.booking_time]);

  if (updated !== true) {
    return <Spin size="large" />;
  } else if (updated === true) {
    return (
      <>
        <BlackNavbar />
        <div className="maxWidth">
          <div className="pt-5 col-12 mxAuto pt-5 row padBottom">
            <div className="col-lg-5 col-11 mxAuto pt-5 bordercolor Radius_16 shadow PadBottom">
              <h4 className="px-3">Booking Details</h4>
              <h6 className="mt-4 mb-2 Bold px-3">{business_name}</h6>
              <form className="px-md-3">
                <Error />
                <div className="row">
                  <div className="form-group col-12 px-0">
                    <select
                      className="form-control"
                      name="Booking_type"
                      value={Booking_type}
                      onChange={onHandleChange}
                    >
                      <option value="">Select your Booking</option>
                      <option value="Normal">Standard Booking</option>
                      {Instant_Offered ? (
                        <option value="InstantBooking">Instant Booking</option>
                      ) : (
                        <option disabled value="InstantBooking">
                          Instant Booking (Turned of by Owner)
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="col-12 col-md-6 px-0 py-0  mb-0 ">
                    <input
                      type="text"
                      className="form-control fnameborder fillcolor"
                      placeholder="First name"
                      name="first_name"
                      value={first_name}
                      onChange={onHandleChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 px-0 py-0  mb-0 ">
                    <input
                      type="text"
                      className="form-control lnameborder fillcolor"
                      placeholder="Last name"
                      name="last_name"
                      value={last_name}
                      onChange={onHandleChange}
                    />
                  </div>
                </div>

                <div className="py-0">
                  <DatePicker
                    className="w-100"
                    disabledDate={(current) => {
                      return moment().add(-1, "days") >= current;
                    }}
                    onChange={onChange}
                  />
                </div>

                <div
                  className={`row pt-2 py-3 ${
                    travelToMeOffered === false ? "d-none" : "show"
                  }`}
                >
                  <label className="font17 mr-auto mb-0 col-8">
                    This Professional offer Services at door Step
                  </label>
                  <div className="col-4 my-auto">
                    <Switch
                      unCheckedChildren="Don't Want"
                      checkedChildren="Wanted"
                      onChange={travelChange}
                      className="ml-auto"
                    />
                  </div>
                </div>
                <div className="px-0 col-12 PadBottom">
                  <p className="Bold font18">Add Services</p>
                  <table className="table table-striped bordercolor">
                    <tbody>{RenderItems}</tbody>
                  </table>
                </div>
                <div>
                  <div className="block col-12 px-0">
                    <p className="Bold font18">Booked Services</p>
                    <div className="PadBottom">
                      {cartItems.length === 0 && <div>No Services Added</div>}
                      {cartItems.map((item) => (
                        <div key={item._id} className="row">
                          <div className="col-8 py-2">
                            <button
                              type="button"
                              onClick={() => onRemove(item)}
                              className="remove"
                            >
                              -
                            </button>
                            {item.service_name}
                          </div>
                          <div className="col-4 text-right">
                            ${item.service_price.toFixed(2)}
                          </div>
                        </div>
                      ))}

                      {cartItems.length !== 0 && (
                        <>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <strong>Total Bill</strong>
                            </div>
                            <div className="col-8 text-right">
                              <strong>${total_bill}</strong>
                            </div>
                            <div className="col-4">
                              <strong>Total Time</strong>
                            </div>
                            <div className="col-8 text-right">
                              <strong>
                                {selectedServiceTime >= 60 ? (
                                  <>
                                    {Math.floor(selectedServiceTime / 60)} hours{" "}
                                    {selectedServiceTime % 60} min
                                  </>
                                ) : (
                                  <>{selectedServiceTime} min </>
                                )}
                              </strong>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 px-0">
                  <p className="Bold font18">Select time</p>
                  {getTime ? (
                    <Spin size="large" />
                  ) : (
                    <ul
                      className="Tags RadioToButton px-0 d-inline-flex verticalScroll"
                      id="verticalScroll"
                    >
                      {time.length > 0 ? (
                        <li className="col-4 col-sm-3 col-md-2 col-lg-3 mxAuto px-1 user-select-none">
                          <input
                            type="radio"
                            id="SelectTime"
                            name="booking_time"
                            value=""
                            disabled
                          />
                          <label className="w-100 py-2 " for="SelectTime">
                            SelectTime
                          </label>
                        </li>
                      ) : null}
                      {time.sort().map((timeSlot) => {
                        return (
                          <li className="col-4 col-sm-3 col-md-2 col-lg-3 mxAuto px-1">
                            <input
                              type="radio"
                              id={timeSlot}
                              name="booking_time"
                              value={timeSlot}
                              onClick={TimeSelect}
                            />
                            <label className="w-100 py-2 " for={timeSlot}>
                              {timeSlot}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <p
                    className={`py-0 text-danger ${
                      time.length < 1 && Booking_type !== "" ? "" : "d-none"
                    }`}
                  >
                    <>Sorry No slots Available for the selected date</>
                  </p>
                </div>
              </form>
            </div>
            <div className=" col-lg-6 col-11 mxAuto pt-5 mt-2 mt-lg-0 bg-white bordercolor Radius_16 shadow padBottom">
              <div className="form-group h-100 px-3">
                <div className="col-12 row ">
                  <h4 className="mr-auto">Make Payment</h4>
                </div>
                <img src={paymentImg} alt="" />
                <form className="col-12 pb-3 px-0 h-75">
                  <div className="h-100">
                    <div
                      className={` ${
                        Booking_type === "InstantBooking" ? "d-none" : "show"
                      }`}
                    >
                      {payment_type === "full" ? (
                        <p>Only Full Payment Accepted</p>
                      ) : payment_type === "deposit" ? (
                        <p>
                          It charges a deposit amount and rest at the time of
                          booking
                        </p>
                      ) : (
                        <>
                          <p className="mr-auto font17">Pay deposit/Full</p>
                          <Switch
                            unCheckedChildren="Pay deposit"
                            checkedChildren="Pay Full"
                            onChange={paymentChange}
                            className="mr-auto"
                          />
                        </>
                      )}
                    </div>
                    <p
                      className={` ${
                        Booking_type === "InstantBooking" ? "" : "d-none"
                      }`}
                    >
                      Instant Booking Support only Full payment
                    </p>

                    {[
                      Booking_type,
                      first_name,
                      last_name,
                      booking_date,
                      booking_time,
                      cartItems.length,
                      first_name.length >= 3,
                      last_name.length >= 3,
                    ].every(Boolean) ? (
                      <div className="mt-4">
                        <Payout total_bill={total_bill} book={validation} />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center h-100 ">
                        <p
                          className="text-center"
                          style={{ height: "fit-content" }}
                        >
                          Fill out your booking details for Payment Options
                        </p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PaymentBooking;
