import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import { Spin } from "antd";
import { NavLink } from "react-router-dom";
import CardImg from "../../assests/CardImg.png";
import axios from "axios";
import { checkIfProfileIsComplete } from "../../helpers/helpers";

const Account = () => {
  const id = localStorage.getItem("venderId");
  const initialstate = {
    first_name: "",
    last_name: "",
    email: "",
    update: false,
    admin_approved: false,
    isProfileComplete: true,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { first_name, last_name, email, update, isProfileComplete } = FormData;
  useEffect(() => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/vendor/get_vendor/${id}`;
    axios.get(link).then((res) => {
      if (res.data.success) {
        const isProfileComplete = checkIfProfileIsComplete(res.data.data, [
          "first_name",
          "last_name",
          "email",
          "contact",
          "gender",
          "personal_address.country",
          "personal_address.street_address",
          "personal_address.apt",
          "personal_address.city",
          "personal_address.State",
          "personal_address.zip_code",
        ]);
        setFormData({
          ...FormData,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          email: res.data.data.email,
          admin_approved: res.data.data.admin_approved,
          update: true,
          isProfileComplete,
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="container col-11 mxAuto maxWidth">
          <h1 className="pt-4 pb-0">Account</h1>
          <p
            style={{
              backgroundColor: "#ea9223",
              borderRadius: "5px",
              padding: "10px",
              display: "inline-block",
            }}
          >
            Blackbooking is going to charged 5% from overall booking.
          </p>
          <p>
            <strong>{first_name + " " + last_name}</strong> {email}{" "}
            <span>
              <strong>
                <NavLink to={`/bussiness/${id}`} className="orangecolor">
                  Go to Profile
                </NavLink>
              </strong>
            </span>
          </p>
          {FormData.admin_approved ? (
            <p
              style={{
                backgroundColor: "#00FF00",
                borderRadius: "5px",
                padding: "10px",
                display: "inline-block",
              }}
            >
              Account verification is completed
            </p>
          ) : (
            <p
              style={{
                backgroundColor: "#FFD700",
                borderRadius: "5px",
                padding: "10px",
                display: "inline-block",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              Your business listing request is still under process. It will take
              48 hours to update you about the business status
            </p>
          )}
          {isProfileComplete ? null : (
            <p
              style={{
                backgroundColor: "#FFD700",
                borderRadius: "5px",
                padding: "10px",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              Personal Info not completed
            </p>
          )}
          <div className=" pt-5">
            <div className=" mxAuto">
              <div className="row">
                <div className="col-md-4 col-11 mxAuto mt-2">
                  <NavLink to={`/personalinfo/${id}`} className="Black">
                    <div className="card Radius_10  account shadow cursor">
                      <div className="card-body p-4 ">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3  uAccount cursor">
                          Personal Info &#62;
                        </h3>
                        <p className="card-text font16 cursor">
                          Personal info details can be viewed and edited.
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 col-11 mxAuto mt-2">
                  <NavLink className="Black" to={"/security/" + id}>
                    <div className="card Radius_10 account shadow cursor">
                      <div className="card-body p-4 ">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3 uAccount cursor">
                          Login &#38; Security &#62;
                        </h3>
                        <p className="card-text font16 cursor">
                          Visit the security page to review the login
                          information.
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 col-11 mxAuto mt-2">
                  <NavLink className="Black" to={"/paymentandpayout/" + id}>
                    <div className="card Radius_10 account shadow cursor">
                      <div className="card-body p-4">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3 uAccount cursor">Payouts &#62;</h3>
                        <p className="card-text font16 cursor">
                          Check out the payment schedules and payout reports.{" "}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 col-11 mxAuto mt-2">
                  <NavLink to="/notifications" className="Black">
                    <div className="card Radius_10  account shadow cursor">
                      <div className="card-body p-4 ">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3 uAccount cursor">
                          Notifications &#62;
                        </h3>
                        <p className="card-text font16 cursor">
                          Explore your account notifications.
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 col-11 mxAuto mt-2">
                  <NavLink className="Black" to="/privacyandsharing">
                    <div className="card Radius_10 account shadow">
                      <div className="card-body p-4">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3 uAccount cursor">
                          Privacy &#38; Sharing &#62;
                        </h3>
                        <p className="card-text font16 cursor">
                          See your privacy and sharing.
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 col-11 mxAuto mt-2">
                  <NavLink className="Black" to={"/invite/" + id}>
                    <div className="card Radius_10 account shadow">
                      <div className="card-body p-4">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3 uAccount cursor">
                          Invite Friends &#62;
                        </h3>
                        <p className="card-text font16 cursor">
                          Send your friends a personal invitation to Black
                          Booking.
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 col-11 accounts mt-2">
                  <NavLink to={"/booking/" + id} className="Black">
                    <div className="card Radius_10 account shadow">
                      <div className="card-body p-4">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3 uAccount cursor">
                          Bookings &#62;{" "}
                        </h3>
                        <p className="card-text font16 cursor">
                          Your account's Bookings record can be found here.
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="col-md-4 col-11 accounts mt-2">
                  <NavLink className="Black" to={"/reviews/" + id}>
                    <div className="card Radius_10 account shadow">
                      <div className="card-body p-4 ">
                        <img src={CardImg} alt=""></img>
                        <h3 className="pt-3 uAccount cursor">
                          Reviews and Reports &#62;
                        </h3>
                        <p className="card-text font16 cursor">
                          For past &#38; upcoming bookings, see Reviews &#38;
                          Reports.
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <p className="text-center ">Need to deactivate your account?</p>
            <u>
              <NavLink to={"/selectreason/" + id} className="orangecolor">
                <h6 className="text-center orangecolor">
                  Take care of that now
                </h6>
              </NavLink>
            </u>
          </div>
        </div>
      </>
    );
  }
};

export default Account;
