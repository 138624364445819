import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Account from "./Vendor/Account";
import Payments from "./Vendor/Payments";
import PersonalInfo from "./Vendor/PersonalInfo";
import Security from "./Vendor/Security";
import Notifications from "./Vendor/Notifications";
import PrivacyandSharing from "./Vendor/PrivacyandSharing";
import ReviewsandReports from "./Vendor/ReviewandReports";
import Booking from "./Vendor/Booking";
import InviteFriends from "./Vendor/InviteFriends";
import BusinessListinginfo from "./Vendor/BusinessListinginfo";
import SelectReason from "./Vendor/DeactivateAccount/SelectReason";
import Deactivate from "./Vendor/DeactivateAccount/Deactivate";
import LiveNotifications from "./Vendor/LiveNotifications";
import VendorChat from "./Vendor/VendorChat";
import FeatureSaloon from "./Vendor/FeatureSaloon";
import EditYourBusiness from "./Vendor/EditYourBussiness/EditYourBussiness";

const VendorLinks = () => {
  return (
    <Switch>
      <Route
        exact
        path="/businessinfo/:_id"
        component={BusinessListinginfo}
      />
      <Route exact path="/account/" component={Account} />
      <Route exact path="/personalinfo/:_id" component={PersonalInfo} />
      <Route exact path="/security/:_id" component={Security} />
      <Route exact path="/paymentandpayout/:_id" component={Payments} />
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/privacyandsharing" component={PrivacyandSharing} />
      <Route exact path="/reviews/:_id" component={ReviewsandReports} />
      <Route exact path="/booking/:_id" component={Booking} />
      <Route exact path="/invite/:_id" component={InviteFriends} />
      <Route exact path="/selectreason/:_id" component={SelectReason} />
      <Route exact path="/deactivate/:_id" component={Deactivate} />
      <Route exact path="/notification/:_id" component={LiveNotifications} />
      <Route exact path="/chat/:vender_id/:user_id" component={VendorChat} />
      <Route exact path="/featured/:_id" component={FeatureSaloon} />
      <Route exact path="/bussiness/:vendorId" component={EditYourBusiness} />
      <Redirect to="/" />
    </Switch>
  );
};

export default VendorLinks;
